.chat-component__wrapper {
  @apply lotw-tw-relative lotw-tw-pb-7;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.chat-component__wrapper--dark-mode {
  border-radius: 8px;
}

.chat__heading {
  display: none;

  @media (min-width: 1200px) {
    display: block;
    font-weight: 500;
    font-size: 18px;
    padding: 0 0.5rem;
    color: #666;
    border-bottom: 1px solid #e1e1e1;
  }
}

.chat-component__input {
  width: 100%;
  height: 50px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 10px;
  color: black;
  font-size: 14px;

  &:focus {
    border-color: black;
  }
}

.chat-component__input--dark-mode {
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid white;
  color: white;
  height: 40px;
}

.chat-comment {
  display: flex;
  align-items: center;
}

.chat-comment--reply {
  background-color: papayawhip;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-bottom: 0.5rem;
}

.chat-comment__notification {
  @apply lotw-tw-mt-5 lotw-tw-mb-2;
  font-weight: 800;
  font-size: 11px;
}

.chat-comment__notification--dark-mode {
  background: white;
  padding: 4px 8px;
  border-radius: 8px;
}

.chat-comment__avatar {
  flex: 0;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  position: relative;
  top: 4px;
}

.chat-comment__content {
  flex: 1;
  margin-left: 1rem;

  & span {
    font-weight: 800;
    font-size: 11px;
  }

  & p {
    font-size: 14px;
    line-height: 1rem;
    word-break: break-word;
  }
}

.chat-comment__content--dark-mode {
  background: rgba(0, 0, 0, 0.4);
  color: white;
  padding: 4px 8px;
  border-radius: 8px;
}

.chat-component__expiration-time-wrapper {
  @apply lotw-tw-flex lotw-tw-items-center lotw-tw-px-4 lotw-tw-py-2;
}

.chat-component__expiration-time-wrapper--dark-mode {
  @apply lotw-tw-mx-4;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  padding: 4px 8px;
  border-radius: 8px;
}

.chat-component__expiration-time {
  font-weight: 800;
  font-size: 13px;
}

.chat-component__send-button {
  @apply lotw-tw-bg-white lotw-tw-text-xl lotw-tw-ml-2 lotw-tw-border;
  flex-shrink: 0;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-component__send-button--dark-mode {
  @apply lotw-tw-bg-white lotw-tw-text-xl lotw-tw-ml-2;
}

.chat-component__heart-button {
  flex-shrink: 0;
  background-color: #eb5757;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-component__heart-button--dark-mode {
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid white;
}

.chat-component__shop-all {
  @apply lotw-tw-mr-2 lotw-tw-px-2;
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  height: 40px;
}

.chat-component__shop-all--replay-mode {
  @apply lotw-tw-inline lotw-tw-px-2 lotw-tw-py-1;
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
}

.hearts {
  position: absolute;
  bottom: 16px;
  width: 50px;
  height: 250px;
}

.heart {
  position: absolute;
  height: 25px;
  width: 25px;
  background-color: red;
  mask-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>');
  mask-repeat: no-repeat;
  mask-position: bottom;
  opacity: 0;
  animation: floating-heart 7s 1 cubic-bezier(0.5, 0.5, 0.5, 0.5);
}

@keyframes floating-heart {
  0% {
    opacity: 0;
    bottom: 0;
    left: 20%;
  }
  10% {
    opacity: 1;
    bottom: 20%;
    left: 50%;
  }
  20% {
    bottom: 40%;
    left: 10%;
  }
  30% {
    bottom: 60%;
    left: 50%;
  }
  40% {
    opacity: 1;
    bottom: 80%;
    left: 5%;
  }
  48% {
    opacity: 0;
    bottom: 100%;
    left: 60%;
  }
  100% {
    opacity: 0;
    bottom: 100%;
    left: 90%;
  }
}
