.label {
  @apply lotw-tw-mb-2 lotw-tw-text-xs lotw-tw-text-gray-500;
}

.addToCartButton {
  border: solid 2px var(--color-text-base);
  color: var(--color-text-base);
  border-radius: 6px;
  padding: 8px 16px;
}
