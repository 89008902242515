.live-component__wrapper {
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;

  & *:not(.live-component__heading) {
    font-family: 'Source Sans Pro', sans-serif;
  }
}

.live-component__wrapper--full-screen {
  position: fixed;
  inset: 0;
  z-index: 3;
  background-color: black;
}

.live-component__heading {
  @apply lotw-tw-leading-7;

  display: none;
  font-size: 26px;
  font-weight: 600;
  margin: 0.5rem 0 1.5rem;
}

.live-component__constrainer {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  gap: 0.5rem;
}

@media (min-width: 470px) {
  .live-component__constrainer {
    min-height: 709px;
  }
}

.live-component__constrainer--full-screen {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  max-height: 100%;
}

.card {
  overflow: hidden;
  width: 100%;
}

.card--tablet {
  @apply card;

  width: auto;
  margin: 0 auto;
  box-shadow: 0 4px 10px rgba(170, 170, 170, 0.25);
  border: 1px solid #e1e1e1;
  border-radius: var(--lotw-card-border-radius);
}

.card--desktop {
  @apply card;

  margin: 0;
}

.live-component__video-container--full-screen {
  border: none;
  border-radius: 0;
}

.live-component__product-container {
  background-color: white;
  display: none;
}

.live-component__product-detail-container {
  @apply lotw-tw-absolute lotw-tw-top-0 lotw-tw-right-0 lotw-tw-bottom-0 lotw-tw-left-0 lotw-tw-overflow-auto lotw-tw-p-2;

  background-color: white;
  z-index: 2;
}

.live-component__chat-container {
  display: none;

  background-color: white;
}

.left-column {
  margin: auto;

  @media (min-width: 768px) {
    width: 100%;
  }
}

.card-heading {
  font-size: 14px;
  text-align: center;
  border-bottom: 1px solid #dadada;
  line-height: unset;
  padding: 0.5rem 0;
  color: white;
  background-color: black;
}

.video-wrapper {
  position: relative;
  overflow: hidden;
  margin: 0;
}

.chat-card {
  position: relative;
  display: none;

  @media (min-width: 1200px) {
    display: flex;
    flex-direction: column;
  }
}

.chat-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

.live-component__chat-overlay {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 1) 70%, transparent);
}

.live-component__shop-all-drawer {
  height: 0;
  transition: height 0.2s ease;

  position: absolute;
  bottom: 0;
  width: 100%;
  background: white;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

  &.is-open {
    height: auto;
  }

  &.is-product-selected {
    height: 50%;
  }
}

.video-js {
  background-color: white;
  min-height: inherit;
}

.home__live-wrapper {
  background-color: #fff9f5;
  padding: 1rem 0 2rem;
}

.live-component__homepage-hero-wrapper {
  display: none;

  padding: 180px 2rem 0;

  & h2 {
    font-size: 46px;
  }

  & p {
    @apply lotw-tw-my-6;
  }

  @media (min-width: 1200px) {
    display: block;
    position: relative;
    height: 100%;
  }
}
