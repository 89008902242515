.most-recently-shown__heading {
  font-weight: 500;
  font-size: 18px;
  padding: 0 0.5rem;
  color: #666;
  border-bottom: 1px solid #e1e1e1;
}

.most-recently-shown__product-wrapper:not(:last-of-type) {
  border-bottom: 1px solid #e1e1e1;
}
