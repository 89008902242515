.shop-all__heading {
  @apply lotw-tw-mb-4;

  line-height: 1rem;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
}

.shop-all__add-products-container {
  @apply lotw-tw-h-full;
  overflow: auto;
  display: flex;
}

.shop-all__product-thumbnail__wrapper {
  @apply lotw-tw-mr-2;

  width: 168px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}

.live-product-thumbnail__image-wrapper {
  @apply lotw-tw-rounded-lg;

  height: 168px;
  width: 168px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #999999;

  & img {
    @apply lotw-tw-rounded-lg;
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
  }
}

.live-product-thumbnail__price {
  @apply lotw-tw-mt-3;
  font-size: 14px;
  font-weight: bold;
}

.live-product-thumbnail__name {
  font-size: 12px;
}
