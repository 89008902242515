.root {
  padding: 0 0.5rem;
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
  font-weight: bold;
  font-size: 18px;
  line-height: 2.85rem;
}

.light {
  font-weight: 500;
  color: #666;
  border-bottom: 1px solid #e1e1e1;
  background-color: unset;
}
