.btn {
  @apply lotw-tw-inline-flex lotw-tw-items-center lotw-tw-px-4 lotw-tw-py-2 lotw-tw-border lotw-tw-text-sm lotw-tw-font-medium lotw-tw-rounded-md;
}

.btn--white {
  @apply lotw-tw-border-gray-300 lotw-tw-text-gray-700 lotw-tw-bg-white hover:lotw-tw-bg-gray-50 focus:lotw-tw-outline-none focus:lotw-tw-ring-2 focus:lotw-tw-ring-offset-2 focus:lotw-tw-ring-main-primary;
}

.btn--primary {
  @apply lotw-tw-text-white lotw-tw-bg-main-primary focus:lotw-tw-outline-none focus:lotw-tw-ring-2 focus:lotw-tw-ring-offset-2 focus:lotw-tw-ring-main-primary;
}
