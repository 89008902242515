.product-info__thumbnail-wrapper {
  position: relative;
  border: solid 1px #d2d6de;
  border-radius: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
  }
}

.product-info__name {
  color: black;
  font-size: 18px;
}

.product-info__identifier-box {
  position: absolute;
  bottom: -12px;
  background-color: black;
  border-radius: 4px;
  height: 26px;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
}

.product-info__price-box {
  font-size: 18px;
  color: black;
  font-weight: 500;
  margin-left: 0;
}

.product-info__add-to-cart-button {
  border: solid 2px var(--color-text-base);
  color: var(--color-text-base);
  border-radius: 6px;
  padding: 8px 16px;
}

.product-info__add-to-cart-button--primary {
  border: none;
  background: var(--color-bg-primary);
  color: var(--color-text-primary);
  font-weight: bold;
}
