.wrapper {
  @apply lotw-tw-flex lotw-tw-h-7;
}

.buttonLeft,
.buttonRight {
  @apply lotw-tw-inline-flex lotw-tw-items-center lotw-tw-px-4 lotw-tw-py-2 lotw-tw-border lotw-tw-border-black lotw-tw-text-sm lotw-tw-font-medium lotw-tw-rounded-md;
}

.buttonLeft {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.buttonRight {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input {
  @apply lotw-tw-border lotw-tw-border-black;
  outline: none;
  text-align: center;
  width: 52px;
}
