:root {
  --z-index-1: 1;
  --z-index-2: 2;
  --z-index-3: 3;
  --z-index-100: 100;

  --color-bg-primary: #3b3d46;
  --color-text-primary: white;

  --color-text-base: #3b3d46;

  --green: #34c759;
  --yellow: #fecb51;
  --red: #ff3b30;
  --blue: #006ced;
  --text-dark-secondary: #444444;
  --text-dark-tertiary: #666666;
  --light-gray: rgba(116, 116, 128, 0.08);
  --gray: rgba(60, 60, 67, 0.6);

  --lotw-card-border-radius: 10px;
}
