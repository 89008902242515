.overlay-info {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  font-weight: bold;
  border-radius: 4px;
  padding: 2px 6px;
  display: flex;
  align-items: center;
}

.overlay-info__live {
  background-color: var(--red);
}
.overlay-info__live--circle {
  background-color: white;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  margin-right: 8px;
}

.overlay-info__mute-button {
  @apply lotw-tw-flex lotw-tw-items-center;

  & svg {
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.32));
  }
}

.overlay-product__bubble {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  border-radius: 4px;
  padding: 6px 8px;
}

.overlay-product__bubble-point {
  height: 16px;
  width: 16px;
  position: absolute;
  right: -16px;
  top: 28px;
  overflow: hidden;

  & div {
    position: absolute;
    left: -11px;
    background-color: rgba(0, 0, 0, 0.4);
    height: 16px;
    width: 16px;
    transform: rotate(45deg);
  }
}

.overlay-product__sold-text {
  & h2 {
    font-weight: bold;
    font-size: 22px;
    line-height: 2.5rem;
  }
}

.overlay-product__price {
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 13px;
  font-weight: bold;
}

.overlay-product__info {
  font-size: 14px;
  line-height: 1rem;
}

.overlay-product__info-label {
  width: 52px;
}

.overlay-product__image {
  border: 4px solid white;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  height: 75px;
  width: 75px;
  border-radius: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.overlay-product__buy-button {
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 13px;
  font-weight: bold;
  position: absolute;
  bottom: -7px;
  left: 21px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
