.root {
  @apply lotw-tw-text-base lotw-tw-inline-flex lotw-tw-items-center lotw-tw-justify-center lotw-tw-px-4 lotw-tw-py-2 lotw-tw-border lotw-tw-rounded-md;
}

.root:active {
  outline: none;
}

.light {
  @apply lotw-tw-border-gray-300 lotw-tw-text-gray-700 lotw-tw-bg-white hover:lotw-tw-bg-gray-50 focus:lotw-tw-outline-none focus:lotw-tw-ring-2 focus:lotw-tw-ring-offset-2 focus:lotw-tw-ring-main-primary;
}

.primary {
  @apply lotw-tw-text-white lotw-tw-bg-main-primary focus:lotw-tw-outline-none focus:lotw-tw-ring-2 focus:lotw-tw-ring-offset-2 focus:lotw-tw-ring-main-primary;
}

.fullWidth {
  @apply lotw-tw-w-full lotw-tw-py-3;
}

.disabled {
  position: relative;
  cursor: not-allowed;

  &::before {
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.6);
  }
}
